.rjel8c2{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;gap:1rem;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;width:clamp(400px, 850px, 90%);}
.t1bcdoyv{font-size:24px;font-weight:700;color:#43CCF8;}
.dtw03m3{color:#C3CBD9;}
@media screen and (width > 640px){.m1cbqk7u{display:none!important;}}
.g7gpck5{display:-ms-grid;display:grid;grid-template-areas:'image' 'content';-webkit-box-pack:center;-ms-flex-pack:center;-webkit-justify-content:center;justify-content:center;gap:1rem;}@media screen and (width > 640px){.g7gpck5{grid-template-areas:'image content';-ms-grid-columns:290px 1fr;grid-template-columns:290px 1fr;}}
.igei3qp{grid-area:image;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-ms-flex-pack:center;-webkit-justify-content:center;justify-content:center;position:relative;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.c1uocfxv{grid-area:content;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;gap:1rem;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;}
.d16m1zhj{gap:1rem;display:none;}@media screen and (width > 640px){.d16m1zhj{color:#C3CBD9;display:-ms-grid;display:grid;grid-template-areas:'title title' 'qr stores';}}
.djdl8yw{font-size:18px;grid-area:title;font-weight:700;}
.q1ou8b88{--qr-size:150px;grid-area:qr;position:relative;width:var(--qr-size);height:var(--qr-size);}@media screen and (width > 1024px){.q1ou8b88{--qr-size:200px;}}
.d51fox0{font-size:14px;}@media screen and (width > 640px){.d51fox0{font-size:16px;}}
.s1w8n4yi{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;grid-area:stores;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-align-items:stretch;-webkit-box-align:stretch;-ms-flex-align:stretch;align-items:stretch;gap:0.5rem;-webkit-box-pack:center;-ms-flex-pack:center;-webkit-justify-content:center;justify-content:center;}
